import React, { useEffect, useState }from "react";
import NumberFormat from "components/number_format";
import { getShippingFee } from "../../../apis/shipping";
import { useFormik } from "formik";
import { toastError, toastSuccess } from 'helpers/toastHelper';
import { createOrder } from "../../../apis/order";
import ServiceModal from "./serviceModal";
import { useNavigate } from "react-router-dom";


const FormVietTelPost = ({
    invoiceDetail,
    invoiceId,
    shippingUnitId,
    pickOption,
    businessId,
    currentUser
  }) => {

  const [weight, setWeight] = useState(0);
  const [note, setNote] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [listTags, setListTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [fee, setFee] = useState();
  const [agree, setAgree] = useState(true);
  const [listOptions, setListOptions] = useState([])
  const navigate = useNavigate();
  const [listMainServices, setListmainServices] = useState([])
  const [mainService, setMainService] = useState('')

  const handleInputCalculFee = async (e) => {
    if (weight <= 0) {
      toastError('Vui lòng nhập khối lượng lớn hơn 0');
      return;
    }
      createShippingFee(invoiceId, shippingUnitId, parseFloat(e.target.value))
  }

  const createShippingFee = async (invoiceId, shippingUnitId, weight) => {
    const params = {
      invoice_id: invoiceId, // Mã đơn hàng
      shipping_id: shippingUnitId, // ĐVVC
      business_association_id: businessId,
      package_info: {
          weight: weight, // Cân nặng gói hàng
      }
    };

    try {
      const { data } = await getShippingFee(params);

      if (data.success === false) {
        toastError(data.data); // Hiển thị thông báo lỗi từ API
        return;
      }

      setMainService(data.data[0].service_code);
      setListOptions(data.data[0].extra_service);
      setFee(data.data[0].fee);
      setListmainServices([]);
      setListmainServices((prevArray) => [
        ...prevArray,
        ...data.data.map(item => ({
          service_code: item.service_code,
          service_name: item.service_name,
          fee: item.fee,
          extra_service: item.extra_service,
        }))
      ]);

    } catch (error) {
      if (error.response && error.response.status === 400) {
        toastError(error.response.data.data); // Hiển thị thông báo lỗi khi status 400
      } else {
        toastError('Đã xảy ra lỗi, vui lòng thử lại sau.'); // Thông báo lỗi chung
      }
    }
  };


  const handleSubmmitShowFee = () => {
    setOpenModal(false)
  }

  const handleClick = () => {
    setOpenModal(true)
  };

  const {
    handleSubmit,
  } = useFormik({
    initialValues: {
      invoice_id: invoiceId,
      shipping_id: shippingUnitId,
      package_info: {
        weight: weight,
      },
      shipment_info: {
        order_service: mainService,
        order_service_add: tags.join(', '),
        order_note: note
      }
    },
    onSubmit: async (values, { resetForm }) => {
      const params = {
        invoice_id: invoiceId,
        shipping_id: shippingUnitId,
        package_info: {
          weight: weight,
        },
        shipment_info: {
          order_service: mainService,
          order_service_add: tags.join(', '),
          order_note: note
        }
      };
      try {
        const response = await createOrder(params);
        if (response.data.success === true) {
          toastSuccess(response.data.data)
          resetForm();
          if(invoiceDetail?.business_association_id) {
            navigate(`/lien-ket-danh-nghiep/thong-tin-don-hang/${invoiceId}/business/${invoiceDetail?.business_association_id}`)
          } else {
            navigate(`/nguoi-dung/thong-tin-don-hang-ban/${invoiceId}`)
          }
        } else {
          toastError(response.data);
        }
      } catch (error) {
        toastError(error.data.data);
      }
    },
  });

  const handleToggle = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    if (checked) {
      setMainService(value);
      const matchedItem = listMainServices.find((item) => item.service_code === value);
      if (matchedItem) {
        setListOptions(matchedItem.extra_service);
        setFee(matchedItem.fee);
      } else {
        console.log('Không tìm thấy');
      }
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h3>Thông tin giao hàng <span className="red-clr">*</span></h3>
        <div className="text-center">
        <button className="btn btn-success fw-bold"
                type="button"
                style={{ padding: "0.3rem 0.5rem" }}
                onClick={(e) => handleSubmit(e)}
                disabled={agree}>
                Tạo vận đơn
          </button>
        </div>
      </div>
      <div className="d-flex mt-4">
        <div className="text-black w-50">
          <div>
            <div className="d-flex justify-content-between border-bottom w-50">
              <div className="w-50">Khối lượng <span className="red-clr">*</span></div>
              <div className="w-50">
                <input
                  className="border-0 w-75"
                  placeholder="0.5"
                  id="weight"
                  type="number"
                  name="weight"
                  value={weight}
                  onBlur={(e) => handleInputCalculFee(e)}
                  onChange={(e) => setWeight(parseFloat(e.target.value))}
                />
                <span>g</span>
              </div>
            </div>
          </div>
          <div className="mt-4 d-flex border-bottom w-50  justify-content-between">
            <div>
              <div>Phí ship</div>
            </div>
            <div className="w-50 text-end text-black-50"><NumberFormat>{fee}</NumberFormat> đ</div>
          </div>
          <div className="mt-4 d-flex border-bottom w-50 justify-content-between">
            <div>
              <div>Thu hộ</div>
            </div>
            <div className="w-50 text-end text-black-50"><NumberFormat>{invoiceDetail?.price_total}</NumberFormat> đ</div>
          </div>
        </div>
        <div className="w-50">
          <div style={{ minHeight: "80px" }}>
            {listMainServices.length > 0 && listMainServices.map((isChecked, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="d-flex w-100 position-relative" key={index}>
                    <div>
                      <input
                        className="form-check-input position-relative ms-0"
                        type="radio"
                        value={isChecked.service_code}
                        id={`checkbox-${index}`}
                        checked={mainService === isChecked.service_code}
                        onChange={(e) => handleToggle(e)}
                      />
                    </div>
                    <div className="pl-2">
                      <span>{isChecked.service_name}</span>
                    </div>
                  </div>
                </React.Fragment>
              )}
            )}
          </div>
        </div>
      </div>
      <div className="d-flex mt-4 justify-content-between">
        <div className="d-flex border-bottom justify-content-between width-40">
          <div>
            <div>Ghi chú</div>
          </div>
          <input
            className="border-0 w-75 text-right"
            placeholder="Ghi chú"
            id="note"
            type="text"
            name="note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
        {listMainServices.length > 0 && (
          <div className="w-50">
            <div></div>
            <div className="d-flex border-bottom w-100 justify-content-between">
              <div>
                Dịch vụ thêm
              </div>
              <div
                className="font-14px text-black-50 cursor-pointer fst-italic text-decoration-underline"
                onClick={handleClick} >
                tùy chọn
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mt-4 form-checkbox font-14px d-flex position-relative">
        <input
          type="checkbox"
          name="checkbox_dieu_khoan"
          className="mr-1 form-check-input"
          onChange={(e) => setAgree(!e.target.checked)}
        />
        <div>
          Tôi đã đọc hiểu và đồng ý với <a className="text-chinh-sach" href="https://cache.giaohangtietkiem.vn/d/7d59b37e9566d6ba55529cc5017ea371.pdf" target="_blank"> Điều khoản và quy định </a> và <a className="text-chinh-sach" href="https://cache.giaohangtietkiem.vn/d/1820ea6760b34ad5971e4efe4902d979.pdf" target="_blank"> Chính sách bảo mật</a>
        </div>
      </div>
      <ServiceModal
        listOptions ={listOptions}
        openModal={openModal}
        mainService= {mainService}
        setOpenModal={setOpenModal}
        setTags={setTags}
        weight={weight}
        handleSubmmitShowFee={handleSubmmitShowFee}
        titleModal="Chính sách tuỳ chọn"
        typeModal={'vtp'}
      />
    </div>
  );
};

export default FormVietTelPost;
