const useInvoiceInfoPrivate = () => {

  const formatInfoPrivate = (infomation, type) => {
    if (infomation && (type === 'seller' || type === 'sales')) {
      const length = infomation.length;
      const obfuscateLength = Math.ceil(length * 0.7);
      const visiblePart = infomation.slice(obfuscateLength);
      const obfuscatedPart = '*'.repeat(obfuscateLength);

      return obfuscatedPart + visiblePart;
    }
    return infomation
  }
  return { formatInfoPrivate };
};

export default useInvoiceInfoPrivate;
