import React, { useState } from 'react';
import FadeIn from 'react-fade-in';
import { Link } from 'react-router-dom';
import SidebarArticlesPlaceholder from './placeholder/sidebar_articles';
import moment from 'moment';

function SidebarArticles(props) {
  const { data, whatPage, isLoading, currentPathname } = props;
  const isVideoPage = whatPage.includes('/videos') || whatPage.includes('/short_videos');
  const [numItems, setNumItems] = useState(4);
  const [loadMore, setLoadMore] = useState(false);
  const handleClick = () => {
    setNumItems(data.length);
    setLoadMore(true);
  };

  return (
    <FadeIn>
      {isLoading ? (
        <FadeIn>
          <SidebarArticlesPlaceholder />
        </FadeIn>
      ) : (
        <div className="box-bd hotnews position-relative">
          <div className="title text-center mt-2 align-items-center">
            <span className="txt text-uppercase  primary-clr">{isVideoPage ? 'Video nổi bật' : 'Tin tức nổi bật'}</span>
          </div>
          {data
            .filter(
              article =>
                `/${!whatPage.includes('/tin-tuc') ? 'short_videos' : 'tin-tuc'}/${article.slug}` != currentPathname,
            )
            .slice(0, numItems)
            .map(function (article, index) {
              return (
                <div key={index}>
                  <Link
                    to={`/${!whatPage.includes('/tin-tuc') ? 'short_videos' : 'tin-tuc'}/${article.slug}`}
                    key={article.id}
                    className="hot-videos p-2 hover"
                  >
                    <div className="row d-flex align-items-center">
                      <div className="col-md-12 col-sm-4 col-xs-4">
                        <div
                          className="bg-img position-relative"
                          style={{
                            backgroundImage: `url(${article.image ?? '/images/logo_2nong.png'})`,
                          }}
                        >
                          {isVideoPage && (
                            <span className="video-play-icon icon-arrow-solid text-center sm-size position-absolute"></span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-8 col-xs-8">
                        <span className="txt line-clamp-02 mt-2 mb-1 fw-semibold">{article.title}</span>
                        <div className="d-inline-block">
                          <span className="icon-time align-middle mr-1"></span>
                          <small>
                            <time dateTime="" title="">
                              {moment(article?.created_at).format('DD-MM-YYYY')}
                            </time>
                          </small>
                        </div>
                        <div className="d-inline-block ml-3">
                          <small>
                            <span
                              className="icon-eye-line01 align-middle mr-1"
                              style={{ color: '#919191', fontSize: '13px' }}
                            ></span>
                            <span>{article.viewed}</span>
                          </small>
                        </div>
                        <div className="d-inline-block ml-3">
                          <small>
                            <span className="icon-comment-line align-middle mr-1"></span>
                            <span>{article.total_comment}</span>
                          </small>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          {!loadMore && (
            <div
              onClick={() => handleClick()}
              className="product-more hoverr border-0 w-100 "
              style={{
                backgroundColor: 'f5f5f5',
                borderRadius: '5px',
                marginBottom: '10px',
              }}
            >
              <span className="txt fw-500 hoverr">
                <u>Xem thêm</u>
              </span>
              <span className="icon-arrow-solid" style={{ fontSize: '5px', marginLeft: '4px', color: 'grey' }}></span>
            </div>
          )}
        </div>
      )}
    </FadeIn>
  );
}

export default SidebarArticles;
