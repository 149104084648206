// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormAddEditProduct .ImageAttachment {
  position: relative;
  height: 150px;
  padding: 10px;
}
.FormAddEditProduct .ImageAttachment .btn-close {
  position: absolute;
  right: 0 !important;
  font-size: 12px;
  top: -2px;
}
.FormAddEditProduct .ImageAttachment img.buttonAddImage {
  object-fit: contain;
}
.FormAddEditProduct .ImageAttachment > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/pages/BusinessAssociation/BusinessProduct/AddEditProduct.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,aAAA;EACA,aAAA;AAAJ;AAEI;EACE,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;AAAN;AAGI;EACE,mBAAA;AADN;AAII;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAFN","sourcesContent":[".FormAddEditProduct {\n  .ImageAttachment {\n    position: relative;\n    height: 150px;\n    padding: 10px;\n\n    .btn-close {\n      position: absolute;\n      right: 0 !important;\n      font-size: 12px;\n      top: -2px;\n    }\n\n    & img.buttonAddImage {\n      object-fit: contain;\n    }\n\n    &>img {\n      width: 100%;\n      height: 100%;\n      object-fit: cover;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
