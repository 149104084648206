import React, { useEffect } from "react";
import { Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import NumberFormat from "components/number_format";
import { imageUrl } from 'lib';

const SellingPage = (props) => {
  const { data, statusCheck } = props;

  const handlePrint = (url) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    data?.map((item) => {
      statusCheck(item.status);
    });
  }, [data]);

  return (
    <div className="row">
      <Card className="p-0">
        <Card.Body>
          <Table>
            <thead>
              <tr className="text-center">
                <th className="line-clamp-01">Mã đơn hàng</th>
                <th className="line-clamp-01">Tên người mua</th>
                <th className="line-clamp-01">Số lượng</th>
                <th className="line-clamp-01">Thời gian đặt</th>
                <th className="line-clamp-01">Tổng tiền</th>
                <th className="line-clamp-01">Trạng thái</th>
                <th className="line-clamp-01"></th>
              </tr>
            </thead>
            {data.length > 0 ? (
              data?.map((item, index) => {
                return (
                  <tbody id="invoice-listing" key={index}>
                    <tr className="text-center">
                      <td className="sku-detail product-detail">
                        { item?.invoice_user_id !== undefined ?
                          <Link
                            to={`/nguoi-dung/thong-tin-don-ban-hoan-tra/${item.id}`}
                          >
                            {item.invoice_user_sku}
                          </Link>
                        :
                          <Link
                          to={`/nguoi-dung/thong-tin-don-hang-ban/${item.id}`}
                          >
                            {item.sku}
                          </Link>
                        }
                      </td>
                      <td className="text-center">
                        <img
                          src={imageUrl(item?.buyer_image)}
                          className="rounded-circle"
                          alt=""
                          style={{ width: '30px', height: '30px' }}
                        />
                        {item.buyer_name}
                      </td>
                      <td>{item.quantity}</td>
                      <td>
                        <small>
                        { item?.invoice_user_id !== undefined ? moment(item.invoice_user_created_at).format("DD-MM-YYYY") : moment(item.created_at).format("DD-MM-YYYY") }
                        </small>
                      </td>
                      <td>
                        <NumberFormat>{ item?.invoice_user_id !== undefined ? item.total_price : item.price_total }</NumberFormat>
                        <span className="ml-1 red-clr font-italic">VNĐ</span>
                      </td>
                      <td className='text-center'>
                        <div className={`mt-1 badge badge-${ statusCheck(item.status).color }`}>
                          {statusCheck(item.status).text}
                        </div>
                      </td>
                      {
                        item?.status === 'ready_to_ship' ?
                          <td key={item.id}>
                            <button className="btn btn-sm btn-success" onClick={() => handlePrint(item.awb_file_url)}>In đơn</button>
                          </td>
                        :
                          <></>
                      }
                    </tr>
                  </tbody>
                );
              })
            ) : (
              <tbody>
                <tr>
                  <td className="empty-data">Không có đơn hàng</td>
                </tr>
              </tbody>
            )}
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SellingPage;
