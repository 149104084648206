// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormAddEditProduct .ImageAttachment {
  position: relative;
  height: 150px;
  padding: 10px;
}
.FormAddEditProduct .ImageAttachment .btn-close {
  position: absolute;
  right: 0 !important;
  font-size: 12px;
  top: -2px;
}
.FormAddEditProduct .ImageAttachment img.buttonAddImage {
  object-fit: contain;
}
.FormAddEditProduct .ImageAttachment > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.FormAddEditProduct .w-img-form {
  width: 25%;
}
.FormAddEditProduct .custom-alert {
  background-color: #f8d7da;
  color: #721c24;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.FormAddEditProduct .alert-message {
  color: #721c24;
}
@media screen and (max-width: 768px) {
  .FormAddEditProduct .col-7,
  .FormAddEditProduct .col-5 {
    width: 100%;
  }
  .FormAddEditProduct .w-img-form {
    width: 33.3%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/AddEditProduct/index.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,aAAA;EACA,aAAA;AAAJ;AAEI;EACE,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;AAAN;AAGI;EACE,mBAAA;AADN;AAII;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAFN;AAME;EACE,UAAA;AAJJ;AAOE;EACE,yBAAA;EACA,cAAA;EACA,aAAA;EACA,kBAAA;EACA,wCAAA;AALJ;AAQE;EACE,cAAA;AANJ;AASE;EAEE;;IAEE,WAAA;EARJ;EAWE;IACE,YAAA;EATJ;AACF","sourcesContent":[".FormAddEditProduct {\n  .ImageAttachment {\n    position: relative;\n    height: 150px;\n    padding: 10px;\n\n    .btn-close {\n      position: absolute;\n      right: 0 !important;\n      font-size: 12px;\n      top: -2px;\n    }\n\n    & img.buttonAddImage {\n      object-fit: contain;\n    }\n\n    &>img {\n      width: 100%;\n      height: 100%;\n      object-fit: cover;\n    }\n  }\n\n  .w-img-form {\n    width: 25%;\n  }\n\n  .custom-alert {\n    background-color: #f8d7da;\n    color: #721c24;\n    padding: 12px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n\n  .alert-message {\n    color: #721c24;\n  }\n\n  @media screen and (max-width: 768px) {\n\n    .col-7,\n    .col-5 {\n      width: 100%;\n    }\n\n    .w-img-form {\n      width: 33.3%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
