import MetaTags from 'react-meta-tags'
function AddMetaTags(props) {
  let { title } = props
  return (
    <div className="wrapper">
      <MetaTags>
        <title>{ title ?  title : "2 Nông - trợ lý canh tác hàng đầu Việt Nam"}</title>
      </MetaTags>
    </div>
  );
}

export default AddMetaTags;
