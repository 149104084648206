import React, { useEffect, useState } from "react";
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toastSuccess } from 'helpers/toastHelper';

import {
  fetchBusinessInvoiceDetail,
  updateBusinessInvoice,
} from "actions/business";
import UseInvoice from "../../../context/Inovice/useInvoice";
import DetectAction from "../../InvoiceDetail/Components/DetectActionByStatus";
import Swal from "sweetalert2";
import OrderInformation from "./Components/OrderInformation";
import InvoiceItems from "./Components/InvoiceItems";
import CancelModal from "./Components/cancelModal";
import { getListShippingOfBusiness } from "../../../apis/shipping";
import { toastError } from "../../../helpers/toastHelper";

function BusinessInvoiceDetail() {
  const { invoiceId, businessId } = useParams();
  const { data, isLoading, error } = useSelector(
    (state) => state.business.business_invoice_detail
  );
  const dispatch = useDispatch();
  const { invoiceStatus } = UseInvoice();
  const [openModal, setOpenModal] = useState(false);
  const [reason, setReson] = useState('');
  const navigate = useNavigate();
  const shop_id = data?.shop_id;

  useEffect(() => {
    dispatch(
      fetchBusinessInvoiceDetail({
        businessId: businessId,
        invoiceId: invoiceId,
      })
    );
  }, [invoiceId, businessId]);

  const handleCancelInvoice = () => {
    setOpenModal(true)
  }

  const handleUpdateBusinessInvoice = async (status) => {
    if (status === "rejected") {
      if (!reason) {
        toastError("Chưa nhập lý do huỷ đơn");
      } else {
        Swal.fire({
          title: "Bạn có chắc chắn?",
          text: "Hủy đơn hàng",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
          cancelButtonText: "Đóng",
        }).then((result) => {
          if (result.isConfirmed) {
            setOpenModal(false)
            toastSuccess("Huỷ đơn thành công")
            dispatch(
              updateBusinessInvoice({
                businessId: businessId,
                invoiceId: invoiceId,
                status: status,
              })
            );
          }
        });
      }
    } else {
      const array = [];
      const { data } = await getListShippingOfBusiness({ shop_id: shop_id, business_id: businessId });
      data.data.forEach((element) => {
        if (element?.available_status === "active") {
          array.push(element?.available_status);
        }
      });
      if (array.length > 0) {
        navigate(`/lien-ket-danh-nghiep/thong-tin-don-hang/${invoiceId}/business/${businessId}/van-don`);
      } else {
        toastError("Chủ doanh nghiệp cần thêm đơn vị vận chuyển trước khi chấp nhận đơn");
      }
    };
  }

  const handlePrint = (url) => {
    window.open(url, '_blank');
  };

  const renderSellerPrintButton = () => {
    if (data?.status === "ready_to_ship") {
      return (
        <div className="mt-2 d-flex ml-2">
          <button
            className="btn btn-success"
            onClick={() => handlePrint(data?.awb_file_url)}
          >
            In đơn
          </button>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <AddMetaTags title={`Thông tin đơn hàng - ${invoiceId}`} />
      <div className="detail-invoice">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            {error ? (
              <p className="fw-bold"> Không tìm thấy thông tin đơn hàng!</p>
            ) : (
              <>
                <OrderInformation data={data} invoiceStatus={invoiceStatus} showReturnInfo={false} />
                <InvoiceItems sellerName={data?.business_association_name || data?.seller_name} invoiceItems={data?.invoice_items} totalPrice={data?.price_total} />
                <div className="mt-2 d-flex">
                  <DetectAction status={data?.status}>
                    {data?.status === "pending" ?
                      (
                        <div className="mt-2 d-flex">
                          <button className="btn btn-danger" onClick={() => handleCancelInvoice()}>Hủy đơn</button>
                          {/* <button className="btn btn-info ml-2 text-white" onClick={() => handleSellerUpdateStatusInvoiceOld("delivered")}>Chấp nhận</button> */}
                          <button className="btn btn-success ml-2" onClick={() => handleUpdateBusinessInvoice('ready_to_ship')}>Xử lý đơn</button>
                        </div>
                      ) : data?.status === "cancelled" || data?.status === "admin_rejected" || data?.status === "rejected" ? (
                        <div className="mt-2 d-flex"></div>
                      ) : (
                        <div className="mt-2 d-flex">
                          <button className="btn btn-danger" onClick={() => handleCancelInvoice()}>Hủy đơn</button>
                        </div>
                      )
                    }
                  </DetectAction>
                  {renderSellerPrintButton()}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <CancelModal
        openModal={openModal}
        handleBusinessUpdateStatusInvoice={handleUpdateBusinessInvoice}
        setOpenModal={setOpenModal}
        setReson={setReson}
      />
    </>
  );
}

export default BusinessInvoiceDetail;
