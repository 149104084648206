import React from 'react';
import { Modal } from 'react-bootstrap';

const CancelModal = ({
  setReson,
  handleBusinessUpdateStatusInvoice,
  openModal,
  setOpenModal
}) => {

  const closeModal = () => setOpenModal(false);

  const handleCancel = () => {
    handleBusinessUpdateStatusInvoice('rejected')
  }
  return (
    <Modal
      show={openModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>Lý do huỷ đơn</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          className="form-control"
          placeholder="Lý do"
          name="reason"
          onChange={(e) => setReson(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-info text-white" onClick={closeModal}>
          ĐÓNG
        </button>
        <button type="button" className="btn btn-primary text-white" onClick={handleCancel}>
          HUỶ ĐƠN
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelModal;
