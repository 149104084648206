import { React } from 'react';
import { imageUrl } from 'lib';
import { Col } from "react-bootstrap";
import "../DiagnosticResult.scss"
const PestDiagnosisResult = ({ data }) => {
  return (
    <>
      <h2 className='text-center'>Kết quả chẩn đoán</h2>
      <div className="list-inline-item form-control-range">
        <div className="float-right">
          <img
            src={imageUrl(data.category.icon)}
            className="rounded-circle image-thumnail box-category"
            alt="icon category"
          />
          <strong className="primary-clr ml-2">{data.category.name}</strong>
        </div>
      </div>
      <div className="cont-center saved-items">
        <div className="images-preview">
          {data.summary_images.map((img) => (
            <div className="image-preview-item">
              <img src={img} alt='Hình ảnh chẩn đoán' className="image-diagnostis" />
            </div>
          ))}
        </div>
        {data.nuti_summaries.lenght === 0 ?
          <p><strong className="primary-clr">Thiếu dinh dưỡng:</strong> (Không phát hiện)</p>
          :
          <>
            {data.nuti_summaries.map((nuti) => (
              <div className="box-info mt-2 p-3 nuti-summary">
                <div><img src={nuti?.ainutrition?.image || data?.summary_images[0] || data.category.image} alt='Hình ảnh chẩn đoán' className="image-thumnail rounded-circle" /></div>
                <Col xs={9}>{`${nuti.suggest} (${nuti.percent}%)`}</Col>
              </div>
            ))}
          </>
        }
        <div className="cont-center saved-items mt-3">
          <strong className="primary-clr">Chẩn đoán bệnh:</strong>
          {data.summaries.map((nuti) => (
            <div className="box-info mt-2 p-3 nuti-summary">
              <Col xs={3}><img src={nuti.aidiagnostic?.image || data?.summary_images[0] || data.category.image} alt='Hình ảnh chẩn đoán' className="image-thumnail rounded-circle" /></Col>
              <Col xs={9}>{`${nuti.suggest} (${nuti.percent}%)`}</Col>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
export default PestDiagnosisResult;

