import React, { useState, useEffect } from 'react';
import { toastError } from 'helpers/toastHelper';
import ReactTimeAgo from 'react-time-ago';
import ModalDeleteConfirmComment from 'components/shared/modalDeleteConfirmComment/modal';
import { connect, useSelector } from 'react-redux';
import * as articleActions from 'actions/article';
import { bindActionCreators } from 'redux';
import InputSubComment from './InputSubComment';
import Modal from 'react-bootstrap/Modal';
import parse from 'html-react-parser';
import { validateStringEditor } from 'lib';
import { toastErrorLogin } from '../../../helpers/toastHelper';
import { isMobile } from 'react-device-detect';
function SubComment(props) {
  const {
    id,
    current_user,
    comment,
    actionLikeComment,
    replyComment,
    handleShowReplyBox,
    showReportModal,
    // userReplyComment,
    showModal,
    hideModal,
    updateChildComment,
    managerUpdateChildComment,
    showInputBox,
    setShowInputBox,
    reportContent,
    setReportContent,
    submitReport,
  } = props;
  const { managerDestroyChildComment, userDestroyChildComment, fetchAnswerCommentArticle, userReplyComment } =
    props.articleActionsCreator;
  const [typeInput, setTypeInput] = useState('');
  const { data: deleteChildComment } = props.deleteChildCommentArticle;
  const listAnswerComment = useSelector(state => state.article.answerCommentArticles.data);
  const [subCommentId, setSubCommentId] = useState(null);
  const [subContent, setSubContent] = useState('');

  useEffect(() => {
    fetchAnswerCommentArticle({ id: id });
  }, [replyComment, comment, deleteChildComment, id]);

  const submitReply = (content, type, replier_id) => {
    const validation = validateStringEditor(content);
    const commentData = {
      id: showInputBox.id,
      content: content,
    };
    if (!current_user) {
      return toastErrorLogin(isMobile);
    }
    switch (validation) {
      case 'invalid':
        return toastError('Vui lòng nhập tối thiểu 6 ký tự');
      case 'too long':
        return toastError('Vui lòng không vượt quá 1000 kí tự');
      default:
        if (type === 'reply') {
          commentData.replier_id = current_user.id;
          userReplyComment({
            id: comment.id,
            parent_id: showInputBox.id,
            content: content,
            replier_id: replier_id,
          });
        } else {
          if (current_user.manager_type !== 'member') {
            managerUpdateChildComment(commentData);
          } else {
            updateChildComment(commentData);
          }
        }
    }
    setSubContent('');
    setShowInputBox(false);
  };

  const deleteSubComment = (id, role) => {
    if (role === 'admin' || role === 'smod') {
      managerDestroyChildComment({ id: id });
      setSubCommentId(null);
    } else {
      userDestroyChildComment({ id: id });
      setSubCommentId(null);
    }
  };

  return (
    <>
      {listAnswerComment.length > 0 &&
        listAnswerComment.map(function (ans) {
          return (
            <div className=" ml-5 mt-1 position-relative" key={ans.id}>
              <div className="user-inf border-0">
                <a href={`/nguoi-dung/${ans?.user_slug?.toString() === 'null' ? ans?.user_id : ans?.user_slug}`}>
                  <div
                    className="bg-img-orgnal avtar40"
                    style={{
                      backgroundImage: `url(${ans.user.image ?? '/images/avatar.png'})`,
                      width: '35px',
                      height: '35px',
                      marginRight: '5px',
                    }}
                  />
                </a>
                <div className="d-inline-block">
                  <a href={`/nguoi-dung/${ans?.user_slug?.toString() === 'null' ? ans?.user_id : ans?.user_slug}`}>
                    <p className="user-name">{ans.user.name}</p>
                  </a>
                  <p className="post-time">
                    <span className="icon-time align-middle"></span> <ReactTimeAgo date={Date.parse(ans.created_at)} />
                  </p>
                </div>

                <div>
                  {ans.parent_id !== null && ans.replier_id !== null ? (
                    <p className="txt-cmt">
                      <span style={{ color: 'blue' }}>@{ans?.replier?.name} </span>
                      {ans && parse(ans.content)}
                    </p>
                  ) : (
                    <p className="txt-cmt">{ans && parse(ans.content)} </p>
                  )}
                  <div className="actions d-flex align-items-center my-2">
                    <div
                      className={` ${ans.user_liked ? 'active' : ''} like d-flex align-items-center mr-3`}
                      onClick={() => actionLikeComment(ans.classable_id, ans.user_liked, ans.classable_type)}
                    >
                      <span className="icon-like01 me-1 fs-6"></span>
                      <span>{ans.user_liked ? 'Đã thích' : 'Thích'}</span>
                      <small>({ans.total_likes === null ? 0 : ans.total_likes})</small>
                    </div>
                    <div className="d-flex align-items-center" style={{ paddingLeft: '6px' }}>
                      <span
                        onClick={() => {
                          handleShowReplyBox(ans.id, 'reply');
                          setTypeInput('reply');
                        }}
                      >
                        Trả lời
                      </span>
                    </div>
                    <div className="dropdown post-report ms-auto">
                      <button
                        className="btn dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="icon-menu-dot"></span>
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="true">
                        {current_user?.id !== ans.user_id && (
                          <li className="dropdown-item">
                            <div className="" onClick={() => showReportModal(ans.id, ans.classable_type)}>
                              <a>Vi phạm</a>
                            </div>
                          </li>
                        )}
                        {current_user?.id == ans.user_id ||
                          current_user?.manager_type == 'admin' ||
                          current_user?.manager_type == 'smod' ||
                          current_user?.manager_type == 'mod' ? (
                          <li className="dropdown-item">
                            <div
                              className=""
                              onClick={() => {
                                setSubContent(ans.content);
                                handleShowReplyBox(ans.id, 'edit');
                                setTypeInput('edit');
                              }}
                            >
                              <a>Cập nhật</a>
                            </div>
                          </li>
                        ) : (
                          ''
                        )}
                        {current_user?.id === ans.user_id ||
                          current_user?.manager_type == 'smod' ||
                          current_user?.manager_type == 'admin' ? (
                          <li className="dropdown-item">
                            <div className="" onClick={() => setSubCommentId(ans.id)}>
                              <a>Xóa</a>
                            </div>
                          </li>
                        ) : (
                          ''
                        )}
                        {subCommentId && (
                          <ModalDeleteConfirmComment
                            isOpen={subCommentId}
                            onDelete={() => deleteSubComment(ans.id, current_user?.manager_type)}
                            onToggle={() => setSubCommentId(null)}
                          />
                        )}
                      </ul>
                    </div>
                    <Modal show={showModal} onHide={hideModal} aria-labelledby="contained-modal-title-vcenter" centered>
                      <Modal.Header closeButton>
                        <Modal.Title>Báo cáo bình luận vi phạm</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <input
                          className="form-control"
                          name="message"
                          placeHolder="Nhập lý do"
                          value={reportContent}
                          onChange={e => setReportContent(e.target.value)}
                        />
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => submitReport(reportContent, ans.id, ans.classable_type)}
                        >
                          {' '}
                          gửi
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  {showInputBox.id === ans.id && (
                    <InputSubComment
                      current_user={current_user}
                      setSubContent={setSubContent}
                      subContent={subContent}
                      submitReplyComment={submitReply}
                      typeInput={typeInput}
                      ans={ans}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}

const mapStateToProps = state => {
  return {
    deleteChildCommentArticle: state.article.deleteChildComment,
  };
};

const mapDispatchToProps = dispatch => ({
  articleActionsCreator: bindActionCreators(articleActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubComment);
