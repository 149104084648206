import React, { useEffect, useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import * as postActions from 'actions/post';
import LeftSidebar from 'components/left_sidebar';
import AppContext from 'components/app_context';
import { validURL } from 'lib';
import Post from 'components/shared/post/index';
import Loader from 'components/loader';
import FadeIn from 'react-fade-in';
import PostsPlaceholder from 'components/placeholder/posts';
import AddMetaTags from 'components/add_meta_tags';
import { DOMAIN, PAGE, PER_PAGE } from 'constants';
import RightSidebar from 'components/right_sidebar';
import * as productActions from 'actions/product';
import NumberFormat from 'components/number_format';
import { getProfile } from 'apis/auth';
import * as authActions from 'actions/auth';
import ScrollToTop from 'components/ScrollToTop';
import * as managerActions from 'actions/manager';
import { imageUrl } from 'lib';
import ModalSharePost from './../components/shared/ModalSharePost/index';
import { toastErrorLogin } from '../helpers/toastHelper';
import { isMobile } from 'react-device-detect';
import CheckIcon from '../assets/images/check.png';

function UserProfile(props) {
  let { userId } = useParams();
  let { data, isLoading, isLoadMore } = props.listPosts;
  let { postActionsCreator } = props;
  let { userActionsCreator } = props;
  let { followUser, fetchFollowing, unFollow } = userActionsCreator;
  let [page, setPage] = useState(PAGE);
  let [profile, setProfile] = useState(null);
  let [pageMore, setPageMore] = useState(1);
  let [postId, setPostId] = useState(null);
  let { productActionsCreator } = props;
  let { fetchProductsByUser, fetchLoadMoreProduct } = productActionsCreator;
  let { data: dataProducts, isLoading: isLoadingProduct, isLoadMore: isLoadMoreProduct } = props.listProducts;
  let list_following = props.followings;
  let {
    fetchPost,
    fetchLoadMorePost,
    fetchPostCatalogue,
    likePost,
    unlikePost,
    addPost,
    deletePost,
    createCommentPost,
    sharePost,
    reportPost,
    fetchCommentPost,
    unlikeComment,
    likeComment,
    replyCommentPost,
    updateComment,
    updateAnswerComment,
    reportComment,
  } = postActionsCreator;
  let { managerUpdateComment, managerUpdateAnswerComment, managerDeletePost } = props.managerActionsCreator;
  let comments = props.listCommentPosts;
  let { followPost, unFollowPost, sharePoint, fetchPointOfUser, updatePointOfUser } = props.authActionsCreator;
  const { setDataFilter, current_user } = useContext(AppContext);
  const [isFollow, setIsFollow] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);
  const [content, setContent] = useState('');
  const point = useSelector(state => state.login.pointOfUser.point);
  let isFollowed = isFollow;
  useEffect(() => {
    fetchPost({ user_id: profile?.id });
    fetchProductsByUser({ user_id: profile?.id });
    fetchFollowing({ page: '', limit: '' });
  }, [fetchPost, fetchProductsByUser, profile?.id]);

  useEffect(() => {
    getProfile(userId).then(res => {
      let result = res['data']['data'];
      if (result) {
        setProfile(result);
      }
    });
  }, [userId]);

  useEffect(() => {
    if (isFollowed) {
      fetchFollowing({ page: '', limit: '' });
    }
    if (current_user) {
      fetchPointOfUser(current_user.id);
    }
  }, [isFollowed, fetchFollowing, current_user]);

  const postFilter = value => {
    setDataFilter(value);
  };

  const actionLike = (id, type, isLiked) => {
    if (isLiked) {
      unlikePost({ classable_id: id, classable_type: type });
    } else {
      likePost({ classable_id: id, classable_type: type });
    }
  };

  const listener = e => {
    setPage(page + 1);
  };

  const listenerProduct = e => {
    setPage(page + 1);
    fetchLoadMoreProduct({ page: page + 1, limit: PER_PAGE });
  };

  const addComment = (id, type, content) => {
    createCommentPost({
      commentable_id: id,
      commentable_type: type,
      content: content,
    });
  };

  const actionSharePost = (id, type) => {
    sharePost({
      classable_id: currentPost.id,
      classable_type: currentPost.classable_type,
      share_type: 'config_share',
      post_type: 'public',
      description: content,
      user: profile?.id,
    });
    setCurrentPost(null);
  };
  function actionSharePoint(access_token, receiver_id, points) {
    sharePoint({
      access_token: access_token,
      receiver_id: receiver_id,
      points: points,
    });
    updatePointOfUser(point - points);
  }
  const actionReportPost = (id, reason) => {
    reportPost({ id: id, reason: reason });
  };

  const actionFollowUser = id => {
    if (current_user) {
      followUser({
        classable_id: id,
        classable_type: 'Shop',
        access_token: current_user?.token_user,
      });
      setIsFollow(true);
    } else {
      toastErrorLogin(isMobile);
    }
  };

  const actionUnFollowUser = id => {
    unFollow({
      classable_type: 'Shop',
      classable_id: id,
      access_token: current_user?.token_user,
    });
    setIsFollow(false);
  };

  const linkImage = () => {
    if (profile?.image === null) {
      return '/images/avatar.png';
    }

    if (validURL(profile?.image)) {
      return profile?.image;
    } else if (!validURL(profile?.image)) {
      return DOMAIN + profile?.image;
    }
  };

  let actionFetchCommentPost = id => {
    fetchCommentPost({ classable_id: id, classable_type: 'Post' });
  };

  const actionLikeComment = (id, type, isLiked) => {
    if (isLiked) {
      unlikeComment({ classable_id: id, classable_type: type });
    } else {
      likeComment({ classable_id: id, classable_type: type });
    }
  };

  const actionReplyComment = (id, content, user_id) => {
    replyCommentPost({ id: id, content: content, replier_id: user_id });
  };

  const actionUpdateComment = (type, id, content) => {
    if (type == 'Comment') {
      if (current_user?.manager_type == 'admin' || current_user.manager_type == 'smod') {
        managerUpdateComment({ id: id, content: content });
      } else {
        updateComment({ id: id, content: content });
      }
    } else {
      if (
        current_user?.manager_type == 'admin' ||
        current_user.manager_type == 'smod' ||
        current_user.manager_type == 'mod'
      ) {
        managerUpdateAnswerComment({ id: id, content: content });
      } else {
        updateAnswerComment({ id: id, content: content });
      }
    }
  };

  const actionReportComment = (user_id, reason, id, type) => {
    reportComment({
      id: user_id,
      reason: reason,
      classable_id: id,
      classable_type: type,
    });
  };

  const actionDelete = id => {
    if (current_user?.manager_type == 'admin') {
      managerDeletePost({ id: id });
    } else {
      deletePost({ id: id });
    }
  };
  const actionSortComment = (id, type) => {
    if (type === 'asc') {
      fetchCommentPost({
        classable_id: id,
        classable_type: 'Post',
        page: 1,
        limit: 5,
        sort: 'total_likes',
      });
    } else {
      fetchCommentPost({
        classable_id: id,
        classable_type: 'Post',
        page: 1,
        limit: 5,
        sort: 'created_at',
      });
    }
  };
  const handleFollowPost = (classable_type, classable_id) => {
    followPost({ classable_id: classable_id, classable_type: classable_type });
  };
  const handleUnFollowPost = (classable_type, classable_id) => {
    unFollowPost({
      classable_id: classable_id,
      classable_type: classable_type,
    });
  };
  return (
    <ScrollToTop>
      <div className="posts">
        <AddMetaTags title={`Trang cá nhân: ${profile?.name} `} />
        <div className="row">
          <LeftSidebar />
          <div className="col-md-6 col-sm-12">
            <div className="cont-center saved-items">
              <ul className="nav nav-tabs justify-content-evenly" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="news-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#user-profile"
                    type="button"
                    role="tab"
                    aria-controls="user-profile"
                    aria-selected="true"
                  >
                    Thông tin
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="news-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#news-saved"
                    type="button"
                    role="tab"
                    aria-controls="news-saved"
                    aria-selected="true"
                  >
                    Bài viết
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="prod-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#prod-saved"
                    type="button"
                    role="tab"
                    aria-controls="prod-saved"
                    aria-selected="false"
                  >
                    Sản phẩm
                  </button>
                </li>
              </ul>
              <div className="tab-content border-0 shadow-none my-3" id="myTabContent">
                <div
                  className="tab-pane fade show active p-3"
                  id="user-profile"
                  role="tabpanel"
                  aria-labelledby="user-profile"
                >
                  <div className="profile-page">
                    <div className="text-center">
                      <div className="upload-avatar">
                        <div className="bg-img-orgnal avtar100" style={{ backgroundImage: `url(${linkImage()})` }} />
                      </div>
                      <p className="fw-bold">
                        <span>{profile?.name}</span>
                        {profile?.role_type === 'expert' && (
                          <span>
                            <img
                              src={CheckIcon}
                              alt="done"
                              style={{
                                width: '17px',
                                height: '17px',
                                marginBottom: '5px',
                              }}
                            />
                          </span>
                        )}
                      </p>
                      {current_user && current_user.id != profile?.id ? (
                        <div className="mrgt-10">
                          {list_following?.data.find(element => element.shopable_id == profile?.shop.id) !==
                            undefined || isFollow == true ? (
                            <button className="btn btn-secondary" onClick={() => actionUnFollowUser(profile?.shop.id)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-check"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                              </svg>{' '}
                              Đã theo dõi
                            </button>
                          ) : (
                            <button className="btn btn-success" onClick={() => actionFollowUser(profile?.shop.id)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-plus"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                              </svg>{' '}
                              Theo dõi
                            </button>
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className="mrgt-10">
                    <small>Số điện thoại:</small> <span>{profile?.phone}</span>
                  </div>
                  <div className="mrgt-10">
                    <small>Địa chỉ:</small> <span>{`${profile?.district_name}, ${profile?.province_name}`}</span>
                  </div>
                </div>
                <div className="tab-pane fade" id="news-saved" role="tabpanel" aria-labelledby="news-tab">
                  {isLoading && (
                    <FadeIn>
                      <PostsPlaceholder />
                    </FadeIn>
                  )}
                  <FadeIn>
                    {data.map(function (post, index) {
                      return (
                        <div key={index}>
                          <Post
                            post={post}
                            postFilter={postFilter}
                            actionLike={actionLike}
                            owner={false}
                            addComment={addComment}
                            current_user={current_user}
                            actionSharePost={actionSharePost}
                            actionReportPost={actionReportPost}
                            actionFetchCommentPost={actionFetchCommentPost}
                            comments={comments.data}
                            setPageMore={setPageMore}
                            setPostId={setPostId}
                            postId={postId}
                            actionLikeComment={actionLikeComment}
                            actionReplyComment={actionReplyComment}
                            actionUpdateComment={actionUpdateComment}
                            actionReportComment={actionReportComment}
                            replyCommentPost={replyCommentPost}
                            actionDelete={actionDelete}
                            handleFollowPost={handleFollowPost}
                            handleUnFollowPost={handleUnFollowPost}
                            setCurrentPost={setCurrentPost}
                            actionSharePoint={actionSharePoint}
                            actionSortComment={actionSortComment}
                            point={point}
                          />
                        </div>
                      );
                    })}
                  </FadeIn>
                  <ModalSharePost
                    post={currentPost}
                    handleClose={() => setCurrentPost(null)}
                    setContent={setContent}
                    onSubmit={actionSharePost}
                    current_user={current_user}
                    content={content}
                  />
                  <Loader isLoading={isLoading} />
                  {isLoadMore && (
                    <div className="post-more" onClick={listener}>
                      <span className="txt">
                        <u className="hover fw-500">Xem thêm</u>
                      </span>
                      <span className="icon-arrow-solid"></span>
                    </div>
                  )}
                </div>
                <div className="tab-pane fade" id="prod-saved" role="tabpanel" aria-labelledby="prod-tab">
                  <FadeIn className="row prod-list" childClassName="col-md-3 col-sm-6 col-xs-12">
                    {dataProducts.map(function (product, index) {
                      let product_image =
                        product.images[0] !== undefined
                          ? imageUrl(product.images[0]['name'])
                          : '/images/product-image-default.png';
                      return (
                        <div key={product.id} className="item-lst">
                          <Link to={`/san-pham/${product?.slug}`}>
                            <div className="bg-img-orgnal" style={{ background: `url(${product_image})` }} />
                            <div className="prod-inf">
                              <div className="txt-name text-capitalize">{product?.title}</div>
                              {product?.province_name ? (
                                <div className="locatn">
                                  <span className="icon-location01" />
                                  <span className="txt-city">{product?.province_name}</span>
                                </div>
                              ) : (
                                <div className="locatn">
                                  {' '}
                                  <span className="icon-location01" />
                                  <span className="txt-city"> Không xác định</span>
                                </div>
                              )}
                              <div className="price-item">
                                <div className="price01">
                                  <span>Giá lẻ:</span>
                                  <span className="price-clr">
                                    &nbsp;
                                    <NumberFormat>{product?.retail_price}</NumberFormat>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </FadeIn>
                  <Loader isLoading={isLoadingProduct} />
                </div>
              </div>
            </div>
          </div>
          <RightSidebar />
        </div>
      </div>
    </ScrollToTop>
  );
}

const mapStateToProps = state => {
  return {
    listPosts: state.post.listPosts,
    listCommentPosts: state.post.listCommentsPost,
    listCommentAnswers: state.post.listCommentsAnswer,
    updateComment: state.post.updateComment,
    managerUpdateComment: state.manager.dataUpdateComment,
    managerUpdateAnswerComment: state.manager.dataUpdateAnswerComment,
    dataCreateComment: state.post.createComment,
    listProducts: state.product.listUserProducts,
    user_data: state.login.user_data,
    followings: state.login.list_following,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    postActionsCreator: bindActionCreators(postActions, dispatch),
    productActionsCreator: bindActionCreators(productActions, dispatch),
    userActionsCreator: bindActionCreators(authActions, dispatch),
    managerActionsCreator: bindActionCreators(managerActions, dispatch),
    authActionsCreator: bindActionCreators(authActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
