import React from "react";
import Loader from "components/loader";
import { DOMAIN } from "constants";
function WeatherInfo({ listWeathers, isLoading, translateDay }) {
  return (
    <>
      <div className="col-md-9 col-sm-12">
        <p className="fw-bold mb-0 text-center fs-3 text-uppercase mt-1 mb-3">Thời tiết nông vụ</p>
        <div className="rt-side position-relative overflow-hidden">
          <div className="row row-cols-5 lst-hrs">
            {listWeathers.current_date &&
              listWeathers.current_date.current_weather_group.map(function (weather, index) {
                return (
                  <div className="col" key={index}>
                    <div className="box-cnt">
                      <div className="fs-6">{weather.hour}:00</div>
                      <div className="mt-2">
                        <img
                          src={`${DOMAIN}/images/weather_icons/${weather.weather_status_icon}.png`}
                          alt="weather icon"
                        />
                      </div>
                      <div className="temp mt-2">
                        <span className="fs-5 temp-numb">{Math.round(weather.temp)}</span>
                        <sup>
                          <span className="fw-normal fs-6">o</span>
                        </sup>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <Loader isLoading={isLoading} />
          <div className="row row-cols-3 lst-stt">
            <div className="col">
              <div className="box-cnt">
                <span className="d-block mb-2">
                  <small className="fw-semibold">Nhiệt độ cao nhất</small>
                </span>
                <div className="d-flex align-items-center">
                  <img src="/images/hightemp-icon.png" alt="high temperature" className="high-temp" />
                  {listWeathers && listWeathers.current_date && (
                    <div className="txt">
                      <span className="numb">{Math.round(listWeathers?.current_date?.temp_max)}</span>
                      <span className="unit">
                        <sup>o</sup>C
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col">
              <div className="box-cnt">
                <span className="d-block mb-2">
                  <small className="fw-semibold">Sức gió</small>
                </span>
                <div className="d-flex align-items-center">
                  <span className="icon-pinwheel"></span>
                  <div className="txt">
                    <span className="numb">{listWeathers?.current_date?.wind_speed}</span>
                    &nbsp;
                    <span className="unit">km/h</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="box-cnt">
                <span className="d-block mb-2">
                  <small className="fw-semibold">Mặt trời mọc</small>
                </span>
                <div className="d-flex align-items-center">
                  <img src="/images/sunrise-icon.png" alt="sunrise" className="sunrise" />
                  <div className="txt">
                    <span className="numb">{listWeathers?.current_date?.sunrise.replace("am", "")}</span>
                    <span className="unit">Sáng</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="box-cnt">
                <span className="d-block mb-2">
                  <small className="fw-semibold">Nhiệt độ thấp nhất</small>
                </span>
                <div className="d-flex align-items-center">
                  <img src="/images/lowtemp-icon.png" alt="low temperature" className="low-temp" />
                  {listWeathers && listWeathers.current_date && (
                    <div className="txt">
                      <span className="numb">{Math.round(listWeathers?.current_date?.temp_min)}</span>
                      <span className="unit">
                        <sup>o</sup>C
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col">
              <div className="box-cnt">
                <span className="d-block mb-2">
                  <small className="fw-semibold">Độ ẩm</small>
                </span>
                <div className="d-flex align-items-center">
                  <span className="icon-humidity"></span>
                  <div className="txt">
                    <span className="numb">{listWeathers?.current_date?.humidity}</span>
                    <span className="unit">%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="box-cnt">
                <span className="d-block mb-2">
                  <small className="fw-semibold">Mặt trời lặn</small>
                </span>
                <div className="d-flex align-items-center">
                  <span className="icon-sunset"></span>
                  <div className="txt">
                    <span className="numb">{listWeathers?.current_date?.sunset.replace("pm", "")}</span>
                    <span className="unit">Chiều</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rt-side py-3 mt-3 position-relative overflow-hidden">
          <Loader isLoading={isLoading} />
          <h5 className="title-line-lt text-uppercase fw-bold">Những ngày tới</h5>

          <table className="table table-hover mt-3 mb-0">
            <tbody>
              {listWeathers.next_day &&
                listWeathers.next_day.map(function (nextWeather, index) {
                  return (
                    <tr key={index} className="weather-name">
                      <th >{translateDay(nextWeather.week_name)}</th>
                      <td>
                        <div className="align-items-center d-flex">
                          <span className="icon-humidity me-1 fs-6"></span>{" "}
                          <small className="fw-semibold txt-humidity">{nextWeather.humidity}%</small>
                        </div>
                      </td>
                      <td className="weather-temp">
                        <div className="align-items-center d-flex">
                          <span className="icon-temp me-1 fs-5 text-black-50"></span>
                          <div className="txt-temp">
                            <small className="">{Math.round(nextWeather.temp_min)}ºC -</small>
                            <small className="ml-1 fw-semibold"> {Math.round(nextWeather.temp_max)}ºC</small>
                          </div>
                        </div>
                      </td>
                      <td className="wth-stt text-capitalize d-flex align-items-center">
                        <img
                          src={`${DOMAIN}/images/weather_icons/${nextWeather.weather_status_icon}.png`}
                          alt="sunrise"
                          className="me-1"
                        />
                        <small className="fw-semibol txt-desc ml-1">{nextWeather.description}</small>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default WeatherInfo;
