import React, { useEffect } from 'react';
import AddMetaTags from 'components/add_meta_tags';
import LeftSidebar from 'components/left_sidebar';
import { fetchBusinessDetail } from 'actions/business';
import './BusinessAction.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

const BusinessAction = () => {
  const { businessId } = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.business.business_detail);
  const user = useSelector((state) => state.login.user_data.current_user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      dispatch(fetchBusinessDetail(businessId))
    } else {
      navigate('/');
    }
  }, [user, businessId]);
  return (
    <>
      <AddMetaTags title={"Danh sách chức năng liên kết danh nghiệp"} />
      <div className="list-action-business">
        <div className='row'>
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <div className="container">
              <div className="row">
                <p className="fs-3 d-flex justify-content-center text-uppercase fw-bold">{data?.name}</p>
              </div>
              <div className='row'>
                <div className='action-product col-md-6'>
                  <Link to={`/lien-ket-danh-nghiep/san-pham/${businessId}`} >
                    <p className='title-txt pointer'>Danh sách sản phẩm</p>
                  </Link>
                </div>
                {data && data.is_owner &&
                  <div className='action-employee col-md-6'>
                    <Link to={`/lien-ket-danh-nghiep/nhan-vien/${businessId}`}>
                      <p className='title-txt pointer'>Danh sách nhân viên</p>
                    </Link>
                  </div>
                }
                <div className='action-invoice col-md-6'>
                  <Link to={`/lien-ket-danh-nghiep/don-hang/danh-sach-don-hang/${businessId}`}>
                    <p className='title-txt pointer'>Danh sách đơn hàng</p>
                  </Link>
                </div>
                <div className='action-view-detail-business col-md-6'>
                  <Link to={`/lien-ket-danh-nghiep/thong-tin-danh-nghiep/${businessId}`}>
                    <p className='title-txt pointer'>Thông tin doanh nghiệp</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessAction;
