import React from 'react';

function FilterInvoiceBox(props) {
  return (
    <>
      <div className="mkt-price">
        <div className="search-box search-box-custom">
          <form>
            <div className="row">
              <div className="col-md-12 col-sm-10 col-xs-12 d-flex invoice-search-box justify-content-center">
                <div className="col-md-4">
                  <button
                    type="button"
                    className={`w-100 custom-padding-tabs btn status-box fw-semibold ${props.statusBox === "pending" && "bg-success text-white"}`}
                    onClick={() => props.filter("pending", props.keyword)}
                  >
                    Chờ xác nhận
                  </button>
                </div>
                <div className="col-md-4">
                  <button
                    type="button"
                    className={`w-100 custom-padding-tabs btn status-box fw-semibold ${props.statusBox === "ready_to_ship" && "bg-success text-white"}`}
                    onClick={() => props.filter("ready_to_ship", props.keyword)}
                  >
                    Chờ lấy hàng
                  </button>
                </div>
                <div className="col-md-4 text-center ">
                  <button
                    type="button"
                    className={`w-100 btn status-box fw-semibold ${props.statusBox === "shipped" && "bg-success text-white"}`}
                    onClick={() => props.filter("shipped", props.keyword)}
                  >
                    Đang giao hàng
                  </button>
                </div>
              </div>
              <div className="col-md-12 col-sm-10 col-xs-12 d-flex invoice-search-box justify-content-center mt-2">
                <div className="col-md-4 text-center">
                  <button
                    type="button"
                    className={`w-100 btn status-box fw-semibold ${props.statusBox === "delivered" && "bg-success text-white "}`}
                    onClick={() => props.filter("delivered", props.keyword)}
                  >
                    Đã giao
                  </button>
                </div>
                <div className="col-md-4 text-center">
                  <button
                    type="button"
                    className={`w-100 btn status-box fw-semibold ${props.statusBox === "cancelled" && "bg-success text-white"}`}
                    onClick={() => props.filter("cancelled", props.keyword)}
                  >
                    Đã hủy
                  </button>
                </div>
                <div className="col-md-4 text-center">
                  <button
                    type="button"
                    className={`w-100 custom-padding-tabs btn status-box fw-semibold ${props.statusBox === "returned" && "bg-success text-white"}`}
                    onClick={() => props.filter("returned", props.keyword)}
                  >
                    Trả hàng
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default FilterInvoiceBox;
