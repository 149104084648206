import React, { useEffect, useState, useContext, useRef } from "react";
import { DOMAIN } from "constants";
import _ from "lodash";
import { Link } from "react-router-dom";
import { getCataloguesArticles } from "apis/catalogue";
import AppContext from "components/app_context";
import { validURL } from "lib";
import moment from "moment";
import ArticleOfCatalogue from "./articleOfCatalogue";
export default function ListArticles(props) {
  const isMounted = useRef(true);
  const { data } = props;
  const [leftItems, setLeftItems] = useState(4);
  const [rightItems, setRigthItems] = useState(4);
  const [loadMore, setLoadMore] = useState(false);
  const [catalogue, setCatalogue] = useState([]);
  const { setSearch } = useContext(AppContext);
  const listDataColumn = data.slice(1);
  const first_article = _.first(data);

  useEffect(() => {
    getCataloguesArticles().then((res) => {
      if (isMounted.current) {
        let result = res["data"]["data"];
        if (result) {
          setCatalogue(result);
        }
      }
    });
    return () => {
      setKeyword("");
      isMounted.current = false;
    };
  }, []);

  const setKeyword = (keyword) => setSearch(keyword);
  const validImage = (img) => {
    const image = validURL(img) ? `${img}` : `${DOMAIN}${img}`;
    return image;
  };
  const filterIndexOfItem = (arr, position) => {
    let newArray = [];
    switch (position) {
      case "left":
        newArray = arr
          .filter((item, index) => index % 2 == 0)
          .slice(0, leftItems);
        return newArray;
      case "right":
        newArray = arr
          .filter((item, index) => index % 2 != 0)
          .slice(0, leftItems);
        return newArray;
    }
  };
  const renderFirstArticle = (article) => {
    return (
      <Link to={`/tin-tuc/${article?.slug}`} className="p-2 mb-2 shadow-hover">
        <div
          className="article-image-first  bg-img-orgnal article-bg position-relative"
          style={{
            backgroundImage: `url(${validImage(article.image ? article.image : '/images/logo.png')}) `,
            backgroundSize: "cover !important",
            width: "100%",
            paddingTop: "50%",
            borderRadius: "2px",
            backgroundPosition: "50%!important",
            marginBottom: "5px",
          }}
        ></div>
        <div className="main-news">
          <h5 className="first-art-title fw-bold">{article.title}</h5>
          <div className="mb-3">
            <div className="d-inline-block">
              <span className="icon-time align-middle mr-1"></span>
              <small dateTime="" title="">
                {moment(article?.created_at).format("DD-MM-YYYY")}
              </small>
            </div>
            <div className="d-inline-block ml-3">
              <small>
                <span
                  className="icon-eye-line01 align-middle mr-1"
                  style={{ color: "#919191", fontSize: "13px" }}
                ></span>
                <small>{article?.viewed}</small>
              </small>
            </div>
            <div className="d-inline-block ml-3">
              <small>
                <span className="icon-comment-line align-middle mr-1"></span>
                <small>{article?.total_comment}</small>
              </small>
            </div>
          </div>
        </div>
      </Link>
    );
  };
  const renderArticleColumn = (data, position) => {
    return (
      <div className="col-md-6 col-sm-12">
        {filterIndexOfItem(data, position).map(function (article, index) {
          return (
            <Link
              key={index}
              to={`/tin-tuc/${article?.slug}`}
              className="news-item d-flex align-items-center sub-news-item px-1 "
            >
              <div className="pt-show">
                <div
                  className="bg-img "
                  style={{
                    backgroundImage: `url(${!article.image
                        ? "/images/photos/no_image.png"
                        : article.image
                      })`,
                  }}
                />
              </div>
              <div>
                <div className="ctnt line-clamp-02 artile-title">
                  {article.title}
                </div>
                <div className="mb-1">
                  <div className="d-inline-block">
                    <span className="icon-time align-middle mr-1"></span>
                    <small>
                      <time dateTime="" title="">
                        {moment(article?.created_at).format("DD-MM-YYYY")}
                      </time>
                    </small>
                  </div>
                  <div className="d-inline-block ml-3">
                    <small>
                      <span
                        className="icon-eye-line01 align-middle mr-1"
                        style={{ color: "#919191", fontSize: "13px" }}
                      ></span>
                      <span>{article?.viewed}</span>
                    </small>
                  </div>
                  <div className="d-inline-block ml-3">
                    <small>
                      <span className="icon-comment-line align-middle mr-1"></span>
                      <span>{article?.total_comment}</span>
                    </small>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    );
  };
  const showMoreArticle = () => {
    setLeftItems((num) => num + 2);
    setRigthItems((num) => num + 2);
    setLoadMore(true);
  };

  return (
    <div className="col-md-6 col-sm-12">
      <div className="cont-center">
        <div className="row mb-2">
          <p className="fs-3 d-flex justify-content-center text-uppercase fw-bold">
            Tin tức
          </p>
          {first_article && renderFirstArticle(first_article)}
          {renderArticleColumn(listDataColumn, "left")}
          {renderArticleColumn(listDataColumn, "right")}
          {leftItems < 15 && rightItems < 15 && (
            <div
              onClick={() => showMoreArticle()}
              className="product-more hoverr border-0 w-100 mb-2 "
              style={{
                backgroundColor: "f5f5f5",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
            >
              <span className="txt fw-500 hoverr">
                <u>Xem thêm</u>
              </span>
              <span
                className="icon-arrow-solid"
                style={{ fontSize: "5px", marginLeft: "4px", color: "grey" }}
              ></span>
            </div>
          )}
        </div>
        {catalogue.map((cat, index) => (
          <ArticleOfCatalogue key={index} catalogue_ids={cat.id} />
        ))}
      </div>
    </div>
  );
}
