import React from "react";
import { Modal, Button } from "react-bootstrap";

const AuthenPopup = ({ show, handleClose, type }) => {

  const handleSkip = () => {
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Hai Nông</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-2">
          <p>Tải ứng dụng Hai Nông để có trải nghiệm tốt hơn</p>
          <a
            href="https://cho2nong.page.link/social"
            className="btn-open btn btn-primary"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleSkip}
          >
            Tải App Hai Nông
          </a>
          {
            type === 'zalo-oa' ?
              <></>
              :
              <div
                className="btn btn-success text-center btn login-modal-button"
                data-toggle="modal"
                data-target="#loginModal"
                onClick={handleSkip}
              >
                ĐĂNG KÝ NHANH
              </div>
          }
          <Button variant="secondary" onClick={handleSkip}>
            Bỏ qua
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AuthenPopup;
