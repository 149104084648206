import * as Yup from 'yup';

export const useProductValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string()
      .required('Tên sản phẩm không được để trống')
      .trim('Tên sản phẩm không được để trống'),
    retail_price: Yup.number()
      .required('Không được để trống')
      .positive('Số tiền là một số lớn hơn 0')
      .integer('Số tiền là một số lớn hơn 0')
      .truncate(),
    quantity: Yup.number()
      .required('Không được để trống')
      .positive('Số lượng là một số lớn hơn 0')
      .integer('Số lượng là một số lớn hơn 0')
      .truncate(),
    promotion_price: Yup.number()
      .nullable()
      .positive('Giá khuyến mãi phải là một số lớn hơn 0')
      .integer('Giá khuyến mãi phải là một số nguyên')
      .test('required-dates', 'Nếu nhập giá khuyến mãi thì phải nhập ngày bắt đầu và ngày kết thúc', function (value) {
        const { promotion_start_date, promotion_end_date } = this.parent;
        if (value !== undefined && value !== null) {
          return !!promotion_start_date && !!promotion_end_date;
        }
        return true;
      }),
    promotion_start_date: Yup.date()
      .nullable()
      .when('promotion_price', {
        is: (price) => price !== undefined && price !== null,
        then: Yup.date()
          .required('Ngày bắt đầu không được để trống')
          .test('is-today-or-later', 'Ngày bắt đầu phải lớn hơn hoặc bằng ngày hiện tại', function (startDate) {
            if (!startDate) return true;
            const today = new Date();
            const inputDate = new Date(startDate);

            // So sánh chỉ năm, tháng, ngày
            return (
              inputDate.getFullYear() > today.getFullYear() ||
              (inputDate.getFullYear() === today.getFullYear() &&
                (inputDate.getMonth() > today.getMonth() ||
                  (inputDate.getMonth() === today.getMonth() &&
                    inputDate.getDate() >= today.getDate())))
            );
          }),
      }),
    promotion_end_date: Yup.date()
      .nullable()
      .when(['promotion_price', 'promotion_start_date'], {
        is: (price, startDate) => price !== undefined && price !== null && !!startDate,
        then: Yup.date()
          .required('Ngày kết thúc không được để trống')
          .test('is-after-or-same-start-date', 'Ngày kết thúc phải lớn hơn hoặc bằng ngày bắt đầu', function (endDate) {
            const { promotion_start_date } = this.parent;
            if (!endDate || !promotion_start_date) return true;

            const startDate = new Date(promotion_start_date);
            const endDateObj = new Date(endDate);

            // So sánh chỉ năm, tháng, ngày
            return (
              endDateObj.getFullYear() > startDate.getFullYear() ||
              (endDateObj.getFullYear() === startDate.getFullYear() &&
                (endDateObj.getMonth() > startDate.getMonth() ||
                  (endDateObj.getMonth() === startDate.getMonth() &&
                    endDateObj.getDate() >= startDate.getDate())))
            );
          }),
      }),
  });
};
