import React from "react";
import { toastError } from 'helpers/toastHelper'
import DatePicker from "react-datepicker";
function Information({
  setName,
  setLotCode,
  setPackingWay,
  setNumberQuantity,
  setCertifications,
  setCreatedDay,
  setVideo,
  video,
  image,
  name,
  lotCode,
  packingWay,
  numberQuantity,
  certifications,
  createdDay,
  code,
  setFilevideo,
  removeImage,
  uploadImage,
}) {
  const uploadVideo = (e) => {
    let file = e.target.files[0];
    let fileSize = (file.size) / (1024 * 1024);
    if (fileSize > 10) {
      toastError("Vui lòng chọn video dưới 10MB")
    } else if (!file.type.includes("mp4")) {
      toastError("Vui lòng chỉ chọn video");
    } else {
      let url = URL.createObjectURL(file);
      setFilevideo(file);
      setVideo(url);
    }
  };
  const handleDate = (date) => {
    setCreatedDay(null);
    setCreatedDay(new Date(date));
  };

  return (
    <div className="row">
      <div className="traceability-form mt-2">
        <div className="row">
          <label htmlFor="name">
            Tên gọi<span className="red-clr">*</span>
          </label>
          <input
            className="traceability-input"
            name="name"
            type="text"
            placeholder="Tên gọi"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="row">
          <label htmlFor="lot_code">
            Mã số<span className="red-clr">*</span>
          </label>
          <input
            className="traceability-input"
            name="lot_code"
            type="text"
            placeholder="Mã số (số lô)"
            required
            value={lotCode}
            onChange={(e) => setLotCode(e.target.value)}
          />
        </div>
        <div className="row">
          <label>Quy cách đóng gói</label>
          <input
            className="traceability-input"
            type="text"
            placeholder="Quy cách đóng gói"
            value={packingWay}
            onChange={(e) => setPackingWay(e.target.value)}
          />
        </div>
        <div className="row">
          <label>Ngày đóng gói</label>
          <DatePicker
            className="form-control date-picker-css"
            placeholderText="Chọn thời gian"
            selected={createdDay}
            onChange={(date) => handleDate(date)}
            dateFormat="dd/MM/yyyy"
            name="update_time"
          />
        </div>
        <div className="row">
          <label>Số ngày cách ly</label>
          <input
            className="traceability-input"
            type="text"
            placeholder="Số ngày cách ly"
            value={numberQuantity}
            onChange={(e) => setNumberQuantity(e.target.value)}
          />
        </div>
        <div className="row">
          <label>Chứng nhận</label>
          <input
            className="traceability-input"
            type="text"
            placeholder="Chứng nhận"
            value={certifications}
            onChange={(e) => setCertifications(e.target.value)}
          />
        </div>
        <div className="row">
          <label htmlFor="myFile" className="text-uppercase mb-2">
            Hình ảnh thu hoạch/đóng gói
          </label>
          <div className="d-flex">
            {image.length > 0 &&
              image.map((file, index) => (

                <div className="ml-4">
                  <img
                    key={index}
                    src={URL.createObjectURL(file)}
                    alt="hinh-anh-thu-hoach"
                    className="traceaility-image position-relative"
                  />
                  <button className="btn remove-img position-absolute " onClick={() => removeImage(file, "info")}>
                    x
                  </button>
                </div>
              ))}
          </div>
          <input
            type="file"
            id="myfile"
            name="myfile"
            lang="vi"
            multiple
            style={{ height: "45px" }}
            className="form-control"
            onChange={(e) => {
              uploadImage(e, "info");
            }}
          />
        </div>
        <div className="row">
          <label htmlFor="myFile" className="text-uppercase mb-2">
            Video thu hoạch/đóng gói
          </label>
          {video && (
            <video className="traceability-video" width="500" height="300" controls>
              <source src={video} type="video/mp4" />
            </video>
          )}
          <input
            type="file"
            id="myfile"
            name="myfile"
            lang="vi"
            style={{ height: "45px" }}
            className="form-control"
            onChange={(e) => {
              uploadVideo(e);
            }}
          />
        </div>
        {code && (
          <div className="row">
            <label htmlFor="myFile" className="text-uppercase mb-2">
              Mã truy xuất
            </label>
            <input
              className="traceability-input"
              type="text"
              placeholder="Mã truy xuất"
              value={code}
              disabled
              readOnly
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default Information;
