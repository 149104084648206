import { useEffect, useContext } from "react";
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as expertActions from "actions/expert";
import * as callActions from "actions/call";
import { validURL } from "lib";
import { DOMAIN, WEB_SOCKET_DOMAIN } from "constants";
import AppContext from "components/app_context";
import Loader from "components/loader";
import { toastErrorLogin } from '../../helpers/toastHelper';
import { isMobile } from 'react-device-detect';

function CallExpert(props) {
  let { fetchExpert } = props.expertActionsCreator;
  let { data: listExperts, isLoading } = props.listExperts;
  let { createCall } = props.callActionsCreator;
  const { current_user } = useContext(AppContext);
  let { token } = props.call;
  useEffect(() => {
    fetchExpert();
  }, [fetchExpert]);

  const callExpert = async (id, name) => {
    if (!current_user) {
      toastErrorLogin(isMobile)
    } else {
      createCall({ receiver_id: id, call_type: "video", name: name });
    }
  };

  if (token) {
    let redirect_call = `${WEB_SOCKET_DOMAIN}/#/room?url=wss%3A%2F%2Fstream.hainong.vn&token=${token}&videoEnabled=1&audioEnabled=1&simulcast=1&dynacast=1&adaptiveStream=1&userId=${current_user.id}`;
    window.open(redirect_call);
  }

  return (
    <>
      <AddMetaTags title={"Gọi chuyên gia"} />
      <div className="call-expert">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <h5 className="ms-2 mb-3 text-uppercase fw-bold primary-clr">
              Danh sách chuyên gia
            </h5>
            {isLoading ? (
              <Loader isLoading={isLoading} />
            ) : (
              <div className="row row-cols-3 d-flex">
                {listExperts &&
                  listExperts.map(function (expert, index) {
                    let avatar = validURL(expert.image)
                      ? expert.image
                      : `${DOMAIN}${expert.image}`;
                    let avatar_user =
                      expert.image !== null ? avatar : "/images/avatar.png";
                    return (
                      <div className="col" key={index}>
                        <div className="box-cont">
                          <div className="d-flex align-items-center mb-2">
                            <img
                              src={avatar_user}
                              alt=""
                              className="rounded-circle avt-img"
                            />
                            <div className="">
                              <p className="fw-bold mb-0">{expert.name}</p>
                              <p className="expert-spec mb-1">
                                <small>Chuyên gia sâu bệnh</small>
                              </p>
                              <p className="rating mb-0">
                                <i className="fa-sharp fa-solid fa-star"></i>
                                <i className="fa-sharp fa-solid fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                                <i className="fa-regular fa-star"></i>
                              </p>
                            </div>
                          </div>
                          <div className="d-none">
                            <p className="text-muted mb-0">
                              <small>{expert.phone}</small>
                            </p>
                            <p className="text-muted mb-0">
                              <small>{expert.email}</small>
                            </p>
                          </div>
                          <button
                            onClick={() => callExpert(expert.id, expert.name)}
                            type="button"
                            className="btn btn-link btn-sm btn-rounded"
                          >
                            Gọi chuyên gia
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    listExperts: state.expect.listExpert,
    call: state.expect.room_call,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    expertActionsCreator: bindActionCreators(expertActions, dispatch),
    callActionsCreator: bindActionCreators(callActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallExpert);
