import { useEffect, useState } from 'react';
import { DOMAIN } from 'constants';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { getCataloguesProducts } from '../../apis/catalogue';
import { getProductId, getlistUnits } from '../../apis/product';
import { getDetailBusinessProduct } from 'apis/business';
import ImageUploading from 'react-images-uploading';
import AddImageIcon from '/assets/images/add-image.png';
import './index.scss';
import { useDispatch } from 'react-redux';
import Quill from './editor';
import { toastError } from 'helpers/toastHelper';
import { loadImageFile } from '../../lib';
import { toastSuccess } from '../../helpers/toastHelper';
import { useProductValidationSchema } from './hooks/useProductValidationSchema';

const validationSchema = useProductValidationSchema();

const AddEditProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productId } = useParams();
  const { businessId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [catalogueOptions, setCatalogueOptions] = useState([]);
  const [listUnits, setListUnits] = useState([]);
  const [images, setImages] = useState([]);
  const [marketType, setMarketType] = useState('farmers_markets');
  const [descriptions, setDescriptions] = useState('');
  const [enoughImage, setEnoughImage] = useState(false);

  const statusProduct = [
    { id: 1, name: "Đang bán" },
    { id: 0, name: 'Ngưng bán' },
  ]
  const { values, setValues, setFieldValue, handleChange, handleSubmit, errors, resetForm, touched } =
    useFormik({
      initialValues: {
        title: '',
        description: '',
        product_code: '',
        hot_pick: false,
        optional_name: '',
        retail_price: 0,
        wholesale_price: 'Liên hệ',
        quantity: 0,
        width: 0,
        height: 0,
        length: 0,
        status: 1,
        product_catalogue_id: 0,
        product_unit_id: 0,
        promotion_start_date: '',
        promotion_end_date: '',
        promotion_price: 0,
      },
      onSubmit: async values => {
        const formData = new FormData();
        if (images.length < 1) return toastError('Hình ảnh sản phẩm không được bỏ trống');
        setIsLoading(true);
        for (const key in values) {
          if (key === 'product_unit_id' && values[key] === 0) {
            values[key] = listUnits[0].id;
          }
          if (key === 'product_catalogue_id' && values[key] === 0) {
            values[key] = catalogueOptions[0].key;
          }
          if (key === 'description') {
            values[key] = descriptions;
          }
          formData.append(key, values[key]);
        }
        if (images.length > 0) {
          const imageArray = [];
          for (const image of images) {
            if (image?.file) formData.append('attachment[file][]', image.file);
            if (!image?.file) imageArray.push(`image_url[]=${image.dataURL}`);
          }
          const results = imageArray.map(e => e).join('&');
          if (imageArray.length > 0) {
            const files = await loadImageFile(`${DOMAIN}/api/v2/generate_images?${results}`);
            for (const file of files) {
              formData.append('attachment[file][]', file);
            }
          }
        }
        formData.append('product_type', marketType);
        if (productId) {
          dispatch({
            type: 'UPDATE_PRODUCT',
            payload: {
              id: productId,
              params: formData,
              callback: () => {
                navigate('/nguoi-dung/san-pham');
              },
            },
          });
          setTimeout(() => {
            setIsLoading(false);
          }, 3000);
        } else {
          dispatch({
            type: 'CREATE_PRODUCT',
            payload: {
              params: formData,
              callback: () => {
                toastSuccess('Tạo sản phẩm thành công!')
                navigate('/nguoi-dung/san-pham');
              },
            },
          });
          setTimeout(() => {
            setIsLoading(false);
          }, 3000);
        }
        setDescriptions('');
      },
      validationSchema: validationSchema,
    });
  const handleChangeImages = imageList => {
    if (imageList.length > 10) return toastError('Không vượt quá 10 ảnh');
    if (imageList.length <= 10) {
      setImages(imageList);
      setEnoughImage(false);
    }
    if (imageList.length == 10) setEnoughImage(true);
  };
  const handleChangeMarketType = async value => {
    const options = await fetchDataOptions(value);
    setMarketType(value);
    setCatalogueOptions(options);
  };
  async function fetchDataOptions(value) {
    const response = await getCataloguesProducts({ catalogue_type: value || marketType });
    const { data } = response.data;
    const options = data.map(it => ({
      label: it.fullname,
      key: it.id,
    }));
    return options;
  }

  async function fetchDataUnits() {
    const response = await getlistUnits();
    const { data } = response.data;
    const options = data.map(it => ({
      name: it.name,
      id: it.id,
    }));
    setListUnits(options);
  }

  async function fetchDetailProduct() {
    const response = await getProductId(productId);
    const { data } = response.data;
    return data;
  }

  async function fetchDetailProductBusiness() {
    const response = await getDetailBusinessProduct({
      productId: productId,
      businessId: businessId,
    });
    const { data } = response.data;
    return data;
  }

  const [showAlert, setShowAlert] = useState(true);

  const handleChangeCata = (e) => {
    const selectedText = e.target.options[e.target.selectedIndex].text.toLowerCase();
    setFieldValue("product_catalogue_id", parseInt(e.target.value));
    setShowAlert(selectedText.includes('phân bón') || selectedText.includes('thuốc'));
  };

  const formatDate = (isoDate) => {
    return isoDate.split("T")[0]; // Trả về phần ngày
  };

  useEffect(() => {
    (async function () {
      fetchDataUnits();
      const catalogueOption = catalogueOptions.find(option => option.key === values.product_catalogue_id)?.label?.toLowerCase();
      setShowAlert(catalogueOption?.includes('phân bón') || catalogueOption?.includes('thuốc') || !productId);
      const arr = [];
      if (businessId && productId) {
        arr.push(fetchDetailProductBusiness());
      }
      if (productId) {
        arr.push(fetchDetailProduct());
      }
      const [product] = await Promise.all(arr);
      const options = await fetchDataOptions(product?.product_type);
      setCatalogueOptions(options);
      if (productId || businessId) {
        await setDescriptions(product.description);
        await setValues({
          title: product.title,
          description: product.description,
          product_code: product.product_code,
          hot_pick: product.hot_pick,
          optional_name: product.optional_name,
          retail_price: product.retail_price,
          wholesale_price: product.wholesale_price > 0 ? product.wholesale_price : 'Liên hệ',
          quantity: product.quantity,
          width: product.width,
          height: product.height,
          length: product.length,
          status: product.status,
          product_catalogue_id: options.find(it => +it.key === +product.product_catalogue_id)?.key || options[0].key,
          product_unit_id: product.product_unit_id,
          promotion_start_date: product.promotion_start_date,
          promotion_end_date: product.promotion_end_date,
          promotion_price: product.promotion_price
        });
        setMarketType(product.product_type);
        setImages(product.images.map(image => ({ dataURL: image.name })));
      }
    })();
    return () => {
      resetForm();
    };
  }, [productId, businessId]);

  return (
    <Card className="FormAddEditProduct">
      <Card.Header>
        <h5 className="title-line-lt text-uppercase fw-bold my-3">{productId ? 'Sửa sản phẩm' : 'Thêm sản phẩm'}</h5>
      </Card.Header>
      <Card.Body>
        <Row className="mb-3">
          <Col xs={showAlert ? 7 : 12}>
            <Form.Group>
              <ImageUploading multiple maxNumber={10} value={images} onChange={handleChangeImages}>
                {({ imageList, onImageRemove, onImageUpload }) => (
                  <div className="d-flex flex-wrap">
                    {imageList.concat(null).map((image, index) =>
                      image ? (
                        <div
                          key={index}
                          className="p-2"
                          style={{ width: showAlert ? '33.3%' : '20%' }}
                        >
                          <div className="border border-secondary border-opacity-50 ImageAttachment shadow-sm">
                            <button className="btn-close" onClick={() => onImageRemove(index)} />
                            <img src={image.dataURL} alt="" />
                          </div>
                        </div>
                      ) : (
                        !enoughImage && (
                          <div
                            key={'empty-image'}
                            className="p-2"
                            style={{ width: showAlert ? '33.3%' : '20%' }}
                          >
                            <div className="d-flex w-100 h-100 bg-white" onClick={onImageUpload}>
                              <div className="ImageAttachment">
                                <img src={AddImageIcon} className="buttonAddImage" />
                              </div>
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                )}
              </ImageUploading>
            </Form.Group>
          </Col>

          {showAlert && (
            <Col xs={5}>
              <div className="custom-alert">
                <span className="alert-message">
                  Đối với danh mục <strong>"Phân Bón"</strong> và <strong>"Thuốc BVTV"</strong>  bạn cần mô tả chi tiết thông tin sản phẩm như trên nhãn hàng hóa và cung cấp giấy chứng nhận đủ điều kiện kinh doanh trong mục hình ảnh
                </span>
              </div>
            </Col>
          )}
        </Row>
        <Form onSubmit={handleSubmit} className="mb-0">
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Mã sản phẩm</Form.Label>
              <Form.Control
                type="text"
                name="product_code"
                value={values.product_code}
                onChange={handleChange}
                placeholder="Mã sản phẩm"
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>
                Tên sản phẩm
                <span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                className={classNames({
                  'is-invalid': touched.title && errors?.title,
                })}
                name="title"
                value={values.title}
                onChange={handleChange}
                placeholder="Tên sản phẩm"
              />
              {touched.title && errors?.title && <div className="invalid-feedback">{errors.title}</div>}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Chọn chợ</Form.Label>
              <Form.Select
                value={marketType}
                name="product_type"
                onChange={e => handleChangeMarketType(e.target.value)}
              >
                <option value="farmers_markets">Chợ nông sản</option>
                <option value="agricultural_materials_market">Chợ vật tư nông nghiệp</option>
                <option value="agricultural_wholesale_market">Chợ sỉ nông nghiệp</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Danh mục</Form.Label>
              <Form.Select value={values.product_catalogue_id} name="product_catalogue_id" onChange={(e) => handleChangeCata(e)}>
                {catalogueOptions?.map((option, index) => (
                  <option value={option.key} key={index}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>
                Chiều rộng(cm)
                <span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                name="width"
                value={values.width}
                onChange={handleChange}
                className={classNames({
                  'is-invalid': touched.width && errors?.width,
                })}
                type="text"
                placeholder="Chiều rộng"
              />
              {touched.width && errors?.width && (
                <div className="invalid-feedback">{errors.width}</div>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>
                Chiều dài(cm)
                <span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                name="length"
                value={values.length}
                onChange={handleChange}
                className={classNames({
                  'is-invalid': touched.length && errors?.length,
                })}
                type="text"
                placeholder="Chiều dài"
              />
              {touched.length && errors?.length && (
                <div className="invalid-feedback">{errors.length}</div>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>
                Chiều cao(cm)
                <span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                name="height"
                value={values.height}
                onChange={handleChange}
                className={classNames({
                  'is-invalid': touched.height && errors?.height,
                })}
                type="text"
                placeholder="Chiều dài"
              />
              {touched.height && errors?.height && (
                <div className="invalid-feedback">{errors.height}</div>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>
                Số lượng
                <span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                name="quantity"
                value={values.quantity}
                onChange={handleChange}
                className={classNames({
                  'is-invalid': touched.quantity && errors?.quantity,
                })}
                type="text"
                placeholder="Số lượng"
              />
              {touched.quantity && errors?.quantity && <div className="invalid-feedback">{errors.quantity}</div>}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Đơn vị</Form.Label>
              <Form.Select value={values.product_unit_id} name="product_unit_id" onChange={handleChange}>
                {listUnits.map((unit, index) => (
                  <option value={unit.id} key={index}>
                    {unit.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col}></Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>
                Giá lẻ(vnđ)
                <span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                name="retail_price"
                className={classNames({
                  'is-invalid': touched.retail_price && errors?.retail_price,
                })}
                value={values.retail_price}
                onChange={handleChange}
                type="text"
                placeholder="Giá lẻ"
              />
              {touched.retail_price && errors?.retail_price && (
                <div className="invalid-feedback">{errors.retail_price}</div>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Giá sỉ(vnđ)</Form.Label>
              <Form.Control
                name="wholesale_price"
                className={classNames({
                  'is-invalid': touched.wholesale_price && errors?.wholesale_price,
                })}
                value={values.wholesale_price}
                onChange={handleChange}
                type="text"
                placeholder="Giá sỉ"
              />
              {touched.wholesale_price && errors?.wholesale_price && (
                <div className="invalid-feedback">{errors.wholesale_price}</div>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Trạng thái</Form.Label>
              <Form.Select value={values.status} name="status" onChange={handleChange}>
                {statusProduct.map((unit, index) => (
                  <option value={unit.id} key={index}>
                    {unit.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>
                Giá khuyến mãi (vnđ)
              </Form.Label>
              <Form.Control
                name="promotion_price"
                className={classNames({
                  'is-invalid': touched.promotion_price && errors?.promotion_price,
                })}
                value={values.promotion_price}
                onChange={handleChange}
                type="text"
                placeholder="Giá khuyến mãi"
              />
              {touched.promotion_price && errors?.promotion_price && (
                <div className="invalid-feedback">{errors.promotion_price}</div>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Ngày áp dụng KM:</Form.Label>
              <Form.Control
                name="promotion_start_date"
                className={classNames({
                  'is-invalid': touched.promotion_start_date && errors?.promotion_start_date,
                })}
                value={formatDate(values.promotion_start_date || '')}
                onChange={handleChange}
                type="date"
                min={new Date().toISOString().split('T')[0]}
                placeholder="Ngày bắt đầu"
              />
              {touched.promotion_start_date && errors?.promotion_start_date && (
                <div className="invalid-feedback">{errors.promotion_start_date}</div>
              )}
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Ngày kết thúc KM:</Form.Label>
              <Form.Control
                name="promotion_end_date"
                className={classNames({
                  'is-invalid': touched.promotion_end_date && errors?.promotion_end_date,
                })}
                value={formatDate(values.promotion_end_date || '')}
                onChange={handleChange}
                type="date"
                min={values.promotion_start_date || new Date().toISOString().split('T')[0]}
                placeholder="Ngày kết thúc"
              />
              {touched.promotion_end_date && errors?.promotion_end_date && (
                <div className="invalid-feedback">{errors.promotion_end_date}</div>
              )}
            </Form.Group>
          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              name="hot_pick"
              checked={values.hot_pick}
              onChange={handleChange}
              label="Sản phẩm nổi bật"
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>
              <span className="fw-bold">Mô tả</span>
            </Form.Label>

            <Quill
              onChange={setDescriptions}
              value={descriptions}
              placeholder="Nhập mô tả sản phẩm"
              id="description"
              name="description"
            />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button variant="success" type="submit" disabled={isLoading}>
              Lưu
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default AddEditProduct;
